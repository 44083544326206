.wrapper {
    width: 100%;
    height: 100%;
    position: relative;
}

.text-wrapper::-webkit-scrollbar {
    display: none
}

.text-wrapper {
    width: 100%;
    height: 100%;
    overflow: scroll;
}

.dots {
    bottom: 0;
    right: -20px;
    position: absolute;
}

p { 
    margin: 0;
    padding: 0;
}
