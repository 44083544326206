#headers-wrapper {
    height: fit-content;
    min-width: var(--min-width);
    
}

.home__header {
    width: fit-content;
    overflow-x: hidden;
    white-space: pre;
    cursor: text;
    font-family: "Source Code Pro", monospace;
    font-size: 10em;
    position: relative;
    border: 1px red;
}

.header-wrapper {
    display: flex;
    flex-direction: row;
}

.cursor-after + #cursor {
    height: var(--height);
    width: 1em;
    background-color: currentColor;
    animation: blink 1s steps(2) infinite;
}

.animate-text {
    animation: advance calc(.2s * var(--length) * var(--animation-speed)) forwards;
}

.animate-text + #cursor {
    animation: none;
}

.animate-steps {
    animation-timing-function: steps(var(--length)), linear;
}

.home__header_hide, .home__header_hide::selection {
    color: transparent;
    
}

.center-headings {
    justify-content: center;
}

@keyframes advance-cursor {
    to { 
        right: 100%;
    }
}

@keyframes advance {
    from { 
        width: 0px
    }
    to {
        width: calc(var(--width));
    }
}

@keyframes blink {
    0% {
        visibility: hidden;
    }
}