.editor {
    height: 100%;
    width: 100%;
    display: inline-flex;
    gap: 10px;
    overflow: hidden;
    font-family: monospace;
    line-height: 21px;
    background: #282a3a;
    border-radius: 2px;
    position: relative;
}

.line-numbers {
    position: absolute;
    top: 0;
    width: 20px;
    text-align: right;
}

.line-numbers span {
    counter-increment: linenumber;
}

.line-numbers span::before {
    content: counter(linenumber);
    display: block;
    color: #506882;
}

textarea {
    height: 100%;
    width: calc(100% - 2em);
    line-height: 21px;
    margin-left: 2em;
    overflow-y: auto;
    overflow-x: auto;
    padding: 0;
    border: 0;
    background: #282a3a;
    color: #FFF;
    outline: none;
    resize: none;
}