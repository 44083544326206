.password-wrapper {
    position: relative;
}

.show-password-button {
    position: absolute;
    right: 0;
}

.show-password-icon {
    cursor: pointer;
    --size: 30px;
    width: var(--size);
    height: var(--size);
}