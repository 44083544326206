.App:has(.login-container) {
    background-color: rgb(29, 29, 29);
}

h1 {
    font-size: 2em;
}

.login-container {
    color: white;
    display:
        /* make sure everything looks good */
        flex;
    align-items: center;
    flex-direction: column;
    position: relative;
    height: 90vh;
    font-family: "Source Code Pro", monospace;
}

.login-form-wrapper {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.login-form {
    border-radius: 15px;
    padding: 30px 20px 10px 20px;
    outline: 6px solid rgb(29, 29, 29);
    background-color: rgb(53, 53, 53);
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 3em;
}

.login-header {
    font-size: .5em;
}

.login-form label {
    min-width: 500px;
    font-size: 1em;
    color: red;
    transition: all .5s;
}

.input-group {
    width: 100%;
}

.input-group:has(:not(:placeholder-shown):valid)>label {
    color: lime;
}

.login-form input[type]:not([type='submit']) {
    width: 100%;
    font-size: .5em;
    font-family: inherit;
    background: transparent;
    color: white;
    border: 1px solid gray;
    padding: 7px 3px;
    outline: none !important;
    border-top: none;
    border-right: none;
    border-left: none;
    margin-bottom: 6px;
    transition: all .2s;
}
.login-form input[type]:not([type='submit']):focus-visible {
    margin-bottom: 5px;
    border-bottom: 2px solid white;
}

/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: white !important;
}

.submit-wrapper {
    width: 100%;
    height: 80px;
    position: relative;
}

.submit-button {
    transition: all .22s;
    margin-top: 20px;
    font-family: inherit;
    font-size: .65em;
    padding: 10px;
    background-color: rgb(29, 29, 29);
    border: transparent;
    border-radius: 10px;
    color: white;
}

.submit-button:hover {
    cursor: pointer;
    background-color: rgb(40, 40, 40);
}

.extra-button {
    margin: 0 5px;
    color: gray;
}

.extra-button:hover {
    color: rgb(52, 52, 255);
}

.errors {
    margin: 0;
    padding: 0;
    font-size: .35em;
    align-self: flex-start;
}

.errors > li {
    display: block;
    transition: .35s all;
    position: relative;
}

.errors > li::before {
    margin-right: 3px;
}

.error-active::before {
    content: '❌';
}

.error-active {
    color: rgb(255, 0, 0);
}

.error-non-active::before {
    content: '✔️';
}

.error-non-active {
    color: lime;
}

@keyframes shake {
    10%, 90% {
      transform: translate3d(-1px, 0, 0);
    }
    
    20%, 80% {
      transform: translate3d(2px, 0, 0);
    }
  
    30%, 50%, 70% {
      transform: translate3d(-4px, 0, 0);
    }
  
    40%, 60% {
      transform: translate3d(4px, 0, 0);
    }
  }