@import url('https://fonts.googleapis.com/css2?family=Neucha&display=swap');

:root {
    --input-radius: 50px;
}
/* 
.container {
    width: 90%;
    display: flex;
    align-items: center;
} */

.input {
    width: 100%;
    display: flex;
    position: relative;
    align-items: center;
}

.input__box {
    width: 100%;
    border-radius: var(--input-radius);
    padding: 20px 30px;
    font-size: 25px;
    border: none;
    transition: 0.2s;
    box-shadow: inset 0 0px 5px black;
}

.input__box:hover {
    cursor: text;
}

.input:has(.input__box:focus) {
    pointer-events: all;
}

.input__box:focus {
    box-shadow: 0 0 10px 10000px var(--hidden-background);
    transition: 0.15s all;
    outline: none;
}

.input__submit {
    position: absolute;
    width: var(--input-radius);
    right: 0;
}

.reset {
    width: 100px;
}

.btn {
    height: 50px;
    margin: 12px;
    border-radius: 50px;
    border: none;
    font-size: 20px;
    font-family: Neucha;
    background-color: #2f74c0;
    color: white;
    transition: 0.15s all;
    box-shadow: 0 0 10px black;
}


.btn:hover {
    cursor: pointer;
    box-shadow: 0 0 12px black;
    background-color: #3b8de5;
}

.btn:active {
    transform: scale(0.65);
    box-shadow: 0 0 5px black;
}