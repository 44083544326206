.modal {
    position: fixed;
    top: 45%;
    left: 50%;
    transform: translate(-50%,-50%);
    padding: 10px 20px 20px 20px;
    font-size: 30px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    border-radius: 20px;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    transition: all 0.2s;
    /* background */
    background: white;
    background: var(--linear-background);
}

.overlay-modal {
    transition: all 0.2s;
    position: fixed;
    top: 0; left: 0; bottom: 0; right: 0;
    background-color: var(--hidden-background);
    z-index: 1000;
}

.appear {
    animation: appear var(--duration);
}

.unappear {
    animation: unappear var(--duration);
}

.fade-in {
    animation: fade-in var(--duration);
}

.fade-out {
    animation: fade-out var(--duration);
}


.buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

button {
    cursor: pointer;
}

.modal__button:last-child {
    margin-right: 0px;
}

.modal__button {
    min-width: 200px;
    font-size: 40px;
    margin-right: 100px;
    padding: 5px 15px 5px 15px;

    border-radius: 20px;
    -webkit-transition: background-color 0.2s linear;
    -ms-transition: background-color 0.2s linear;
    transition: background-color 0.2s linear;
}

.modal__button:hover {
    background-color: rgba(50, 50, 50);
    cursor: pointer;
}

.modal__message {
    transition: none;
    border-radius: 20px;
    padding: 10px;
    margin: 20px auto;
    text-align:center;
}

.modal__heading { 
    margin-bottom: 0px;
    font-size: 1.5em;
    color: black;
    font-weight: 500;
}

.modal__blend {
    mix-blend-mode: darken;
    background-color: black;
    color: white;
}

.warning-icon {
    width: 100px;
    height: 100px;
    align-self: center;
    border-radius: 50%;
    color: black;
}

.xButton {
    opacity: 0.4;
    width: 30px;
    height: 20px;
    position: absolute;
    right: 2%;
}

.xButton:hover {
    opacity: 1;
    cursor: pointer;
    border-bottom: 2px solid black;
}

@keyframes fade-in {
    from    { opacity:0 }
    to      { opacity:1 } 
}

@keyframes fade-out {
    from    { opacity:1 }
    to      { opacity:0 }
}


@keyframes unappear {
    to {
        margin-top: calc(1.2 * var(--height));
    }
}

@keyframes appear {
    from {
        margin-top: calc(-1.2 * var(--height));
    }
}