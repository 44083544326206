.main-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    flex-grow: 1;
    width: 100%;
    height: 100%;
    border-top: 2px solid black;
    overflow: hidden;
    position: relative;
    font-family: "Source Code Pro", monospace;
}

.explorer {
    width: 100%;
    height: 100px;
    resize: block;
    background-color: red;
}

.line-numbers, .line-numbers li{
    font-size: 1em;
    display: block;
    margin: 0;
    padding: 0;
}

#console {
    background-color: var(--background);
    --padding-side: 10px;
    width: calc(100% - (var(--padding-side) * 2));
    white-space:pre-wrap;
    font-family: inherit;
    overflow: auto;
    padding: 5px var(--padding-side) 20px var(--padding-side);
    margin: 0;
}

#console li {
    padding: 3px 0px 3px 5px;
}

#explorer {
    position: relative;
    width: 100%;
    height: 100%;
    color: white;
    background: var(--background);
    overflow: hidden;
}

#explorer:hover {
    overflow: auto;
}

#run-button {
    position: absolute;
    right: 0;
    top: 7px;
}

.Resizer {
    opacity: 0;
    position: relative;
    background: darkGray;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -moz-background-clip: padding;
    -webkit-background-clip: padding;
    background-clip: padding-box;
}

.Resizer:hover {
    opacity: 1;
}

.Resizer.horizontal {
    height: 10px;
    margin: -5px 0;
    background: var(--slider-background);
    border-top: 3px solid rgba(255, 255, 255, 0);
    border-bottom: 3px solid rgba(255, 255, 255, 0);
    cursor: row-resize;
    width: 100%;
}

.Resizer.vertical {
    width: 10px;
    margin: 0 -5px;
    background: var(--slider-background);
    border-left: 5px solid rgba(255, 255, 255, 0);
    border-right: 5px solid rgba(255, 255, 255, 0);
    cursor: col-resize;
}


.Resizer.disabled {
    cursor: not-allowed;
}

.Resizer.disabled:hover,
.Resizer.disabled:active {
    border-color: transparent;
}

.Resizer::after,
.Resizer::before {
    content: "";
    border-left: 1px solid #333;
    position: absolute;
    top: 50%;
    transform: translateY(-100%);
    right: 0;
    display: inline-block;
    height: 20px;
    margin: 0 2px;
}

.Resizer::before {
    left: 0;
}