.todos__item {
    word-wrap: break-word;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 1rem;
    width: 17vw;
    height: 100px;
    margin: 5px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    background-color: rgb(219, 195, 9);
    border-radius: 5px;
    font-size: 1.5rem;
    border: 1px solid black
}

.todos__item:hover {
    transform: scale(1.1);
    background-color: rgb(243, 216, 9);
    z-index: 1;
}

.todos__item__text {
    width: 80%;
    height: 100%;
}

textarea {
    resize: none;
    overflow-y: scroll;
    width: 100%;
    height: 100%;
}

.hide-background {
    pointer-events: all;
    z-index: 2;
    box-shadow: 0 0 0 max(100vh, 100vw) var(--hidden-background);
}

.pop {
    animation: disappear 200ms;
}

.todos__item-done {
    text-decoration: line-through;
}

.icons {
    display: flex;
    flex-direction: column;
    height: 100%;
    margin-left: 2vw;
}

.icon {
    width: 100%;
    height: calc(100% / 3);
    padding: 0.1em;
    transition: 0.15s all;
}

.icon:hover {
    background: rgb(207, 214, 216, 0.5);
    border-radius: 50%;
    cursor: pointer;
    transform: scale(1.1)
}

@keyframes disappear {
    to {
        transform: scale(0);
    }
}