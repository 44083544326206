.navbar {
    position: fixed;
    z-index: 1;
    width: 100vw;
    height: 50px;
    padding-top: 10px;
    background: #2c84e1;
    display: flex;
}

.navbar-footer {
    width: 100vw;
    height: 50px;
    padding-top: 10px;
}

.heading {
    pointer-events: all;
    padding-left: 40px;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 40px;
    color: white;
    z-index: 1;
    text-align: center;
    transition: all 0.2s;
}

.heading:hover {
    text-transform: uppercase;
    background: linear-gradient(to right, rgba(0,92,246,1) 8%, rgba(255,0,249,1) 40%, rgba(255,0,249,1) 60%, rgba(0,92,246,1) 92%);
    background-size: 200% auto;
    color: #fff;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: textclip 1.6s linear infinite;
    cursor: pointer;
}
@keyframes textclip {
    to {
        background-position: 200% center;
    }
}

.nav-item {
    margin-left: 30px;
    text-decoration: none;
    font-size: 30px;
    color: white;
    text-align: center;
    padding: 5px 0;
    transition: all 0.2s;
}

.nav-item:hover {
    color: rgb(200, 200, 200);
    cursor: pointer;
}