.root {
    margin: 0;
    padding-left: 10px;
}

.f-name {
    word-break: keep-all;
}

.folder li {
    display: block;
}

summary.f-name {
    list-style-type: '';
}

details[open] > summary.f-name {
    list-style-type: '';
}